/*!
* Version: 4.1.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";

// Hopeui Design system
@import "./hope-ui-design-system/variables";
@import "./hope-ui-design-system/variables-dark";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";
@import "./bootstrap/variables-dark";
@import "./bootstrap/maps";
@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./custom/auth/authentication";
@import "./custom/kanban/kanban";
@import "./custom/pricing/pricing";
@import "./custom/ui-kit/ui-kit";

// Dark Custom
@import "./custom/dark/dark-custom";
table,td,th,tr {
    color: black !important;
}
.custom-dotted-border {
    border: 1px dashed #8080804f;
}
.cursor-pointer {
    cursor: pointer !important;
}
.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-capitalize {
    text-transform: capitalize;
}


.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    opacity: 1;
    transform: scale(1);
}
.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}


//marqee style
@keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .marquee-container {
    background: transparent !important;
    border-radius: 0.5rem;
  }
  
  .img-fluid {
    border-radius: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }